.y-axis path {
  display: none
}

.y-axis line {
  stroke: #ddd;
  stroke-dasharray: 2 2;
  pointer-events: none;
}


.axis text {
  font-size: 12px;
  font-family: 'Lato'
}
