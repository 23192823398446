.y-axis path {
  display: none
}

.y-axis line {
  stroke: #ddd;
  stroke-dasharray: 2 2;
  pointer-events: none;
}


.axis text {
  font-size: 12px;
  font-family: 'Lato'
}


:root {
  font-size: 62.5%;
}

:root body {
  font-family: 'Lato';
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover {
  color: rgb(35, 83, 125);
}

@font-face {
  font-family: 'icomoon';
  src:  url(https://cdn.results.civiqs.com/static/media/icomoon.70490140.eot);
  src:  url(https://cdn.results.civiqs.com/static/media/icomoon.70490140.eot#iefix) format('embedded-opentype'),
    url(https://cdn.results.civiqs.com/static/media/icomoon.d50ebf24.ttf) format('truetype'),
    url(https://cdn.results.civiqs.com/static/media/icomoon.aa46f10e.woff) format('woff'),
    url(https://cdn.results.civiqs.com/static/media/icomoon.a4581d31.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-civiqs-logo:before {
  content: "\E901";
}

.icon-civiqs-map:before {
  content: "\E900"
}

