
:root {
  font-size: 62.5%;
}

:root body {
  font-family: 'Lato';
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover {
  color: rgb(35, 83, 125);
}

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?348kn1');
  src:  url('fonts/icomoon.eot?348kn1#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?348kn1') format('truetype'),
    url('fonts/icomoon.woff?348kn1') format('woff'),
    url('fonts/icomoon.svg?348kn1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-civiqs-logo:before {
  content: "\e901";
}

.icon-civiqs-map:before {
  content: "\e900"
}
